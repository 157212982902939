import {useLocalStorage} from './useLocalStorage';
import {useAuth} from '../auth';

export const useAssessmentFormStorage = (key?: string) => {
    const auth = useAuth();
    const userKey = auth?.user?.id || 0;
    let storageKey = `assessment-form-${key ? `${key}-` : ''}${userKey}`;
    const [answers, setAnswers, clearAnswers] = useLocalStorage(storageKey, {});

    return [answers, setAnswers, clearAnswers] as const;
}
